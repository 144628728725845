.dice-roller {
    text-align: center;
    margin: 20px;
  }
  
  .selectors {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  button {
    margin: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .rolled-dice {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .die {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #f1f1f1;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  