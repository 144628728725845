.simulation-page {
    padding: 15px;
  }
  
  .simulation-table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
  }
  
  .simulation-table th,
  .simulation-table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .simulation-table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    background-color: #f8f9fa;
  }
  
  .simulation-table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .highlighted {
    background-color: #fff3cd !important;
  }
  
  @media (max-width: 767.98px) {
    .simulation-table {
      font-size: 0.875rem;
    }
  
    .simulation-table th,
    .simulation-table td {
      padding: 0.5rem;
    }
  }
  
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .form-label {
    margin-bottom: 0.5rem;
  }
  
  .input-group {
    margin-bottom: 1rem;
  }
  
  h1, h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .btn-secondary {
    margin-bottom: 1rem;
  }