.controls-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .control-container {
    flex: 1 1 calc(50% - 0.5rem);
    min-width: 200px;
  }
  
  @media (max-width: 575px) {
    .control-container {
      flex: 1 1 100%;
    }
  }
  
  .control-container .form-control {
    width: 100%;
  }
  
  .control-container .input-group {
    flex-wrap: nowrap;
  }
  
  .control-container .form-check {
    margin-bottom: 0.5rem;
  }
  
  .control-container .form-check-inline {
    margin-right: 1rem;
  }

  .z-index-100 {
    z-index: 100;
  }
  
  .position-absolute {
    position: absolute;
  }
  
  .w-100 {
    width: 100%;
  }
  