.teams-page {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.reshuffle-button {
  margin-bottom: 20px;
  align-self: flex-end;
}

.share-button {
  margin-bottom: 20px;
  align-self: flex-end;
  margin-left: 10px;
}

.teams-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.team-column {
  width: 48%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.team-name-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-name-input {
  width: 90%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  font-weight: bold;
  text-align: left;
  background-color: inherit;
  color: inherit;
}

.sort-icon {
  cursor: pointer;
  margin-right: 8px;
}

.player-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.player-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #f8f8f8;
}

.player-name {
  flex-grow: 1;
  font-size: 14px;
}

.player-actions {
  display: flex;
  gap: 8px;
  margin-left: 10px;
}

.player-options-icon {
  cursor: pointer;
}

.unassigned-players {
  margin-top: 20px;
}

.unassigned-players h2 {
  margin-bottom: 16px;
  font-size: 18px;
}

.unassigned-player-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.unassigned-player-item {
  background-color: #f0f0f0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  cursor: grab;
}

.unassigned-player-item:active {
  cursor: grabbing;
}

.shared-view h2 {
  font-size: 18px;
  margin-top: 20px;
}

.shared-view h3 {
  font-size: 16px;
  margin-top: 10px;
}

.shared-view ul {
  list-style-type: none;
  padding-left: 0;
}

.shared-view ul li {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.game-settings {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.setting-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .teams-container {
    flex-direction: column;
  }

  .team-column {
    width: 100%;
    margin-bottom: 20px;
  }
}

.select-jack-button {
  margin-bottom: 20px;
  align-self: flex-end;
  margin-left: 10px;
}
