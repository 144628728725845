.lottery-picker {
  text-align: center;
  margin: 20px;
}

.selectors {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

button {
  margin: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.loading-spinner {
  margin-top: 20px;
  font-size: 18px;
  color: #007bff;
}

.lottery-tickets {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ticket-wrapper {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column; /* Change to column to stack ticket number and ticket */
  align-items: center;
}

.ticket-number {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px; /* Adjust margin to separate ticket number from the ticket */
}

.ticket {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
}

.number-ball {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 18px;
  color: black;
  text-align: center;
}

.number-ball.first-range {
  background-color: #aab0dd; /* Light blue for first range */
}

.number-ball.second-range {
  background-color: orange;
}
