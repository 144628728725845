.brcl-page {
  padding: 16px 8px;
  max-width: 1200px;
  margin: 0 auto;
}

.brcl-page h1 {
  text-align: center;
  margin-bottom: 16px;
  font-size: 1.5rem;
}

.selection-info {
  text-align: center;
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: bold;
}

.team-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 16px;
}

.team-controls .ant-input-number {
  width: 200px;
}

@media (min-width: 768px) {
  .brcl-page h1 {
    font-size: 2rem;
  }

  .selection-info {
    font-size: 1.2rem;
  }
}


.selection-controls {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
}
