.App {
  text-align: center;
  padding: 20px;
}

.fixed-width-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.ad-space {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
}

.loading-spinner {
  margin-top: 20px;
  font-size: 18px;
  color: #28a745;
}

.lottery-tickets {
  margin-top: 20px;
}

.ticket-wrapper {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.ticket {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.number-ball {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin: 5px;
  font-size: 18px;
  color: black;
}

.number-ball.first-range {
  background-color: #69b9e4; /* Light blue for first range */
}

.number-ball.second-range {
  background-color: orange;
}
