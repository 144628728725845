.home {
  text-align: center;
  margin-top: 50px;
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.home-link {
  display: inline-block;
  width: 100%;
  max-width: 300px;
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.home-link:hover {
  background-color: #0056b3;
}