.random-number-generator {
    text-align: center;
    margin: 20px auto;
    max-width: 500px;
  }
  
  .input-tile {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .tile-title {
    background-color: #5792d2;
    color: white;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    margin: -20px -20px 20px -20px;
  }
  
  .selectors {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  .range-input {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .range-input .form-label {
    width: 30%;
    text-align: left;
    margin-right: 10px;
  }
  
  button {
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #5792d2;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #5792d2;
  }
  
  .loading-spinner {
    margin-top: 20px;
    font-size: 18px;
    color: #007bff;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .results-tile {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .number {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin: 5px;
    font-size: 18px;
    color: white;
    text-align: center;
    background-color: #5792d2;
  }
  
  .timestamps {
    margin-top: 10px;
  }
  